import Button from "@components/elements/button"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import React from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"
import Grid from "@components/elements/grid"
import { IconWrapper } from "@components/common/icon-wrapper"
import Image from "@components/image"
import Div from "@components/elements/div"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"

export const ImplementationServices = ({ handleClick }) => {
  const { color } = useTheme()

  const { imageData } = useStaticQuery(graphql`
    query ImplementationServices {
      imageData: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "2110b5c6-cc03-4b4d-b6a1-c78c0241dc05"
              "3295d7d0-eda1-4dc0-b5ee-42c8386f4273"
              "87e8078b-fc68-4096-8d34-6e8a4b6d1cab"
              "f274801a-80bf-4ca4-8cc7-94d007a0c204"
            ]
          }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)

  const images = processImages(imageData.edges)

  return (
    <Flex alignItems="flex-start" gap="1rem">
      <Heading as="h2" color={color.blue600} margin="4rem 0 1rem">
        Implementation Services
      </Heading>
      <Paragraph color={color.blue700}>
        We’re in this together. Let Klipfolio experts handle all or just part of
        your implementation. From metric and dashboard creation to data stack
        integration, you can rely on our data specialists and engineers to help
        you save time and get results from your data faster. Contact us for a
        custom quote.
      </Paragraph>
      <Paragraph color={color.blue700} margin="0 0 1rem">
        <b>Price: $220 USD/hr</b> (minimum 5 hrs)
      </Paragraph>
      <Grid columns="40px 1fr" gap="2rem 1.5rem" margin="0 0 2rem">
        <IconWrapper>
          <Image
            file={images["2110b5c6-cc03-4b4d-b6a1-c78c0241dc05"]}
            objectFit="scale-down"
            height={40}
          />
        </IconWrapper>
        <Div>
          <Heading color={color.blue600} as="h4" margin="0 0 1rem">
            Data Stack Integration
          </Heading>
          <Paragraph color={color.blue700} fontSize="1.1rem" lineHeight="1.5">
            We’ll review your existing data stack and find solutions on how to
            best integrate Klipfolio products.
          </Paragraph>
        </Div>
        <IconWrapper>
          <Image
            file={images["3295d7d0-eda1-4dc0-b5ee-42c8386f4273"]}
            objectFit="scale-down"
            height={40}
          />
        </IconWrapper>
        <Div>
          <Heading color={color.blue600} as="h4" margin="0 0 1rem">
            APIs, Data Connectors, Data Feeds and Data Sources
          </Heading>
          <Paragraph color={color.blue700} fontSize="1.1rem" lineHeight="1.5">
            Let our engineering team do the heavy work. We’ll navigate data
            warehouses, semantic layers and APIs, or connect to your data with
            data feeds.
          </Paragraph>
        </Div>
        <IconWrapper>
          <Image
            file={images["87e8078b-fc68-4096-8d34-6e8a4b6d1cab"]}
            objectFit="scale-down"
          />
        </IconWrapper>
        <Div>
          <Heading color={color.blue600} as="h4" margin="0 0 1rem">
            Metric and Visualization Creation
          </Heading>
          <Paragraph color={color.blue700} fontSize="1.1rem" lineHeight="1.5">
            Our analytics experts will create metrics (including calculated
            metrics) or visualizations based on your existing data feeds or
            data&nbsp;sources.
          </Paragraph>
        </Div>
        <IconWrapper>
          <Image
            file={images["f274801a-80bf-4ca4-8cc7-94d007a0c204"]}
            objectFit="scale-down"
            height={40}
          />
        </IconWrapper>
        <Div>
          <Heading color={color.blue600} as="h4" margin="0 0 1rem">
            Dashboard and Report Creation
          </Heading>
          <Paragraph color={color.blue700} fontSize="1.1rem" lineHeight="1.5">
            Working together, we’ll help you create your first set of dashboards
            and reports to share with your stakeholders. After diving into your
            reporting requirements, we’ll map out a plan for the metrics, KPIs
            and visualizations you need.
          </Paragraph>
        </Div>
      </Grid>
      <Button
        onClick={handleClick}
        notice={false}
        background={color.blue600}
        arrow
      >
        Learn more
      </Button>
    </Flex>
  )
}

ImplementationServices.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
