import React, { useCallback } from "react"
import Layout from "@components/layout"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Modal from "@components/elements/modal"
import { Form } from "@components/common/form"
import HubspotForm from "react-hubspot-form"
import { FormWrapper } from "@components/styles/form-wrapper"
import { ImplementationServices } from "@components/pages/services/sections/implementation-services"
import { HireAPartner } from "@components/pages/services/sections/hire-a-partner"
import { ServicesTestimonial } from "@components/pages/services/sections/services-testimonial"
import { ServicesHeader } from "@components/pages/services/sections/services-header"
import Heading from "@components/elements/heading"
import { useTheme } from "styled-components"
import Paragraph from "@components/elements/paragraph"
import Button from "@components/elements/button"
import List from "@components/elements/list"

const ServicesPage = () => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const { color } = useTheme()

  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  return (
    <Layout
      title="Klipfolio Services: Fast Onboarding & Implementation for Optimal Results"
      titleExtension=""
      description={`Save time and get great results with Klipfolio's expert services. Quick onboarding, team training, and customized implementation for superior business outcomes.`}
      marginless
      fullWidth
    >
      <Modal show={modalOpen} setModalState={setModalOpen}>
        <FormWrapper>
          <Form>
            <HubspotForm
              portalId="7477725"
              formId="344ede1a-eca1-4a2b-b1f0-9b4734bffcb0"
            />
          </Form>
        </FormWrapper>
      </Modal>
      <ServicesHeader />
      <Div textContainer margin="0 auto 10rem">
        <ServicesTestimonial />
        <Flex gap="4rem">
          {/* <SixtyDayPOC */}
          <Flex alignItems="flex-start" gap="1rem">
            <Heading as="h2" color={color.blue600} margin="4rem 0 1rem">
              60-Day Proof of Concept
            </Heading>
            <Paragraph color={color.blue700}>
              Accelerate the evaluation process, include all essential business
              requirements, and build a solid foundation for the application
              of&nbsp;Klipfolio within your organization. Our proven 60-day
              method includes:
            </Paragraph>
            <List color={color.blue700} gap="0" margin="0 0 1rem 1rem">
              <li>
                A thorough understanding of your current state and future goals.
              </li>
              <li>An in-depth data and infrastructure review.</li>
              <li>
                A plan that identifies, designs and implements a data stack and
                data tables that support your initiatives.
              </li>
              <li>Identification of key metrics.</li>
              <li>A customized set of metrics and metric dashboards.</li>
              <li>
                Onboarding for your business users as they explore and analyze
                metrics.
              </li>
            </List>
            <Button
              onClick={handleClick}
              notice={false}
              background={color.blue600}
              arrow
            >
              Learn more
            </Button>
          </Flex>

          {/* OnboardingTraining */}
          <Flex alignItems="flex-start" gap="1rem">
            <Heading
              id="onboarding"
              as="h2"
              color={color.blue600}
              margin="2rem 0 1rem"
            >
              Onboarding & Training
            </Heading>
            <Paragraph color={color.blue700}>
              Get your team up and running fast with customized onboarding and
              training delivered by our Professional Services experts. We tailor
              the experience to meet the needs of technical and non-technical
              users and ensure everyone gets the most out of Klipfolio.
            </Paragraph>
            <Paragraph color={color.blue700} margin="0 0 1rem">
              We recommend Onboarding & Training to all new Klipfolio customers.
              <br />
              <b>Price: $220 USD/hr</b> (minimum 5 hrs)
            </Paragraph>
            <Button
              onClick={handleClick}
              notice={false}
              background={color.blue600}
              arrow
            >
              Learn more
            </Button>
          </Flex>

          {/* Dedicated Data Hero */}
          <Flex alignItems="flex-start" gap="1rem">
            <Heading as="h2" color={color.blue600} margin="4rem 0 1rem">
              Dedicated Data Hero
            </Heading>
            <Paragraph color={color.blue700}>
              Level up your team&apos;s skills with monthly one-on-one training.
              Get expert implementation advice with a program tailored to your
              business and project goals.
            </Paragraph>
            <Paragraph color={color.blue700}>
              Learn how to navigate 3rd party APIs and build data feeds, data
              sources, metrics and visualizations. Our experts will also guide
              you in the best practices for account architecture, setup and
              organization.
            </Paragraph>
            <Paragraph color={color.blue700} margin="0 0 1rem">
              <b>Price: $379/mth</b> as a monthly subscription - includes 2
              hours of customized sessions each month and Priority Support.
              Service hours cannot be carried over from one month to the next.
            </Paragraph>
            <Button
              onClick={handleClick}
              notice={false}
              background={color.blue600}
              arrow
            >
              Learn more
            </Button>
          </Flex>

          <ImplementationServices handleClick={handleClick} />
        </Flex>
      </Div>
      <HireAPartner handleClick={handleClick} />
    </Layout>
  )
}

export default ServicesPage
